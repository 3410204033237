export default {
    "11155111": {
        "LynxStakingToken": {
            "address": "0x347370278531Db455Aec3BFD0F30d57e41422353",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_totalSupplyOfTokens",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InsufficientAllowance",
                    "inputs": [
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "allowance",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "needed",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InsufficientBalance",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "balance",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "needed",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidApprover",
                    "inputs": [
                        {
                            "name": "approver",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidReceiver",
                    "inputs": [
                        {
                            "name": "receiver",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidSender",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidSpender",
                    "inputs": [
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "Approval",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Transfer",
                    "inputs": [
                        {
                            "name": "from",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "allowance",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "approve",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "balanceOf",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "decimals",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint8",
                            "internalType": "uint8"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "name",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "string",
                            "internalType": "string"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "symbol",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "string",
                            "internalType": "string"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "totalSupply",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transfer",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferFrom",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "from",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                }
            ],
            "tx_hash": "0x43084903238b8bb454720c2298195fbf7338283f70971d9191fb2ec5328ad73d",
            "block_number": 4886808,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "MockPolygonRoot": {
            "address": "0xDD60a8E632c13fb777Ca76C7FE5670031202Edab",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_rootApplication",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableInvalidOwner",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AuthorizationUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorConfirmed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OwnershipTransferred",
                    "inputs": [
                        {
                            "name": "previousOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "confirmOperatorAddress",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rootApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "setRootApplication",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "application",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "transferOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x304bed5b4870f49600a6357b5b385c6e08f64bd9a086279bd624e826bc520c56",
            "block_number": 4886826,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "TACoApplication": {
            "address": "0x329bc9Df0e45f360583374726ccaFF003264a136",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_token",
                            "type": "address",
                            "internalType": "contract IERC20"
                        },
                        {
                            "name": "_tStaking",
                            "type": "address",
                            "internalType": "contract IStaking"
                        },
                        {
                            "name": "_minimumAuthorization",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_minOperatorSeconds",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_rewardDuration",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_deauthorizationDuration",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_commitmentDurationOptions",
                            "type": "uint64[]",
                            "internalType": "uint64[]"
                        },
                        {
                            "name": "_commitmentDeadline",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressEmptyCode",
                    "inputs": [
                        {
                            "name": "target",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressInsufficientBalance",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "FailedInnerCall",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "OwnableInvalidOwner",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "SafeCastOverflowedUintDowncast",
                    "inputs": [
                        {
                            "name": "bits",
                            "type": "uint8",
                            "internalType": "uint8"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "SafeERC20FailedOperation",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AuthorizationDecreaseApproved",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationDecreaseRequested",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationIncreased",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationInvoluntaryDecreased",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationReSynchronized",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "CommitmentMade",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "endCommitment",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "ManualChildSynchronizationSent",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorBonded",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "previousOperator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "startTimestamp",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorConfirmed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OwnershipTransferred",
                    "inputs": [
                        {
                            "name": "previousOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardAdded",
                    "inputs": [
                        {
                            "name": "reward",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardDistributorSet",
                    "inputs": [
                        {
                            "name": "distributor",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardPaid",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "beneficiary",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "reward",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardsWithdrawn",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Slashed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "penalty",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        },
                        {
                            "name": "investigator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "reward",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "REWARD_PER_TOKEN_MULTIPLIER",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "adjudicator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "approveAuthorizationDecrease",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizationDecreaseRequested",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizationIncreased",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizationParameters",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "_minimumAuthorization",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "authorizationDecreaseDelay",
                            "type": "uint64",
                            "internalType": "uint64"
                        },
                        {
                            "name": "authorizationDecreaseChangePeriod",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "authorizedOverall",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "authorizedStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "availableRewards",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "bondOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "childApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoRootToChild"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDeadline",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption1",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption2",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption3",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption4",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "confirmOperatorAddress",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "deauthorizationDuration",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getActiveStakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_maxStakingProviders",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "allAuthorizedTokens",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "activeStakingProviders",
                            "type": "bytes32[]",
                            "internalType": "bytes32[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getBeneficiary",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "beneficiary",
                            "type": "address",
                            "internalType": "address payable"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getStakingProvidersLength",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "involuntaryAuthorizationDecrease",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "isAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isOperatorConfirmed",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "lastTimeRewardApplicable",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "lastUpdateTime",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "makeCommitment",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_commitmentDuration",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "manualChildSynchronization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "minOperatorSeconds",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "minimumAuthorization",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "operatorToStakingProvider",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingAuthorizationDecrease",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "periodFinish",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pushReward",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_reward",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "registerOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "remainingAuthorizationDecreaseDelay",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "resynchronizeAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rewardDistributor",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardDuration",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardPerToken",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint160",
                            "internalType": "uint160"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardPerTokenStored",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint160",
                            "internalType": "uint160"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardRateDecimals",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "setAdjudicator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_adjudicator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setChildApplication",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_childApplication",
                            "type": "address",
                            "internalType": "contract ITACoRootToChild"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setRewardDistributor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_rewardDistributor",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "slash",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_penalty",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_investigator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "stakingProviderInfo",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "operatorConfirmed",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "operatorStartTimestamp",
                            "type": "uint64",
                            "internalType": "uint64"
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "deauthorizing",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "endDeauthorization",
                            "type": "uint64",
                            "internalType": "uint64"
                        },
                        {
                            "name": "tReward",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "rewardPerTokenPaid",
                            "type": "uint160",
                            "internalType": "uint160"
                        },
                        {
                            "name": "endCommitment",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviderToOperator",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "tStaking",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IStaking"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "token",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IERC20"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "withdrawRewards",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0xd5d4f1abe3e9eb2d92b02cd7f22ee7f6b514d9c15d578c94ecb1543afd6207c1",
            "block_number": 4886820,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "TestnetThresholdStaking": {
            "address": "0x18006f9A84C0bAD4CD96Aa69C7cE17aD760cDaD2",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "OwnableInvalidOwner",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "OwnershipTransferred",
                    "inputs": [
                        {
                            "name": "previousOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "application",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IApplication"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "authorizationIncreased",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizedStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rolesOf",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "beneficiary",
                            "type": "address",
                            "internalType": "address payable"
                        },
                        {
                            "name": "authorizer",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "setApplication",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_application",
                            "type": "address",
                            "internalType": "contract IApplication"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setRoles",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setRoles",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_owner",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_beneficiary",
                            "type": "address",
                            "internalType": "address payable"
                        },
                        {
                            "name": "_authorizer",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setStakes",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_tStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_keepInTStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_nuInTStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "stakedNu",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakes",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "tStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "keepInTStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "nuInTStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviderInfo",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "beneficiary",
                            "type": "address",
                            "internalType": "address payable"
                        },
                        {
                            "name": "authorizer",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "tStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "keepInTStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "nuInTStake",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0xeb083a1906cd8825653e4077cccc9caae00007624f4ac0dba29f49d82ec41ea2",
            "block_number": 4886812,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        }
    },
    "80002": {
        "BetaProgramInitiator": {
            "address": "0xf47dde316D994a050b8b4e5986e0790309979697",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_coordinator",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        },
                        {
                            "name": "_executor",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressEmptyCode",
                    "inputs": [
                        {
                            "name": "target",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressInsufficientBalance",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "FailedInnerCall",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "SafeERC20FailedOperation",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "FailedRequestRefunded",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        },
                        {
                            "name": "refundAmount",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RequestCanceled",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RequestExecuted",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        },
                        {
                            "name": "ritualId",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RequestRegistered",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        },
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]",
                            "indexed": false
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": false
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer",
                            "indexed": false
                        },
                        {
                            "name": "payment",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "NO_RITUAL",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "cancelInitiationRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "coordinator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "currency",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IERC20"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "executeInitiationRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "executor",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRequestsLength",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "refundFailedRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "registerInitiationRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]"
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "requests",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "payment",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "tx_hash": "0xbc06258dbf24b6da9d45619a3048e6894a2779a198d4b7e6cdc48470e8e93ee6",
            "block_number": 5199800,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "Coordinator": {
            "address": "0xE9e94499bB0f67b9DBD75506ec1735486DE57770",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_application",
                            "type": "address",
                            "internalType": "contract ITACoChildApplication"
                        },
                        {
                            "name": "_currency",
                            "type": "address",
                            "internalType": "contract IERC20"
                        },
                        {
                            "name": "_feeRatePerSecond",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AccessControlBadConfirmation",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "AccessControlEnforcedDefaultAdminDelay",
                    "inputs": [
                        {
                            "name": "schedule",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AccessControlEnforcedDefaultAdminRules",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "AccessControlInvalidDefaultAdmin",
                    "inputs": [
                        {
                            "name": "defaultAdmin",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AccessControlUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "neededRole",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressEmptyCode",
                    "inputs": [
                        {
                            "name": "target",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressInsufficientBalance",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "FailedInnerCall",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "SafeCastOverflowedUintDowncast",
                    "inputs": [
                        {
                            "name": "bits",
                            "type": "uint8",
                            "internalType": "uint8"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "SafeERC20FailedOperation",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AggregationPosted",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "node",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "aggregatedTranscriptDigest",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminDelayChangeCanceled",
                    "inputs": [],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminDelayChangeScheduled",
                    "inputs": [
                        {
                            "name": "newDelay",
                            "type": "uint48",
                            "internalType": "uint48",
                            "indexed": false
                        },
                        {
                            "name": "effectSchedule",
                            "type": "uint48",
                            "internalType": "uint48",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminTransferCanceled",
                    "inputs": [],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminTransferScheduled",
                    "inputs": [
                        {
                            "name": "newAdmin",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "acceptSchedule",
                            "type": "uint48",
                            "internalType": "uint48",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "EndRitual",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "successful",
                            "type": "bool",
                            "internalType": "bool",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "MaxDkgSizeChanged",
                    "inputs": [
                        {
                            "name": "oldSize",
                            "type": "uint16",
                            "internalType": "uint16",
                            "indexed": false
                        },
                        {
                            "name": "newSize",
                            "type": "uint16",
                            "internalType": "uint16",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "ParticipantPublicKeySet",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "participant",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "publicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word2",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                }
                            ],
                            "internalType": "struct BLS12381.G2Point",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "ReimbursementPoolSet",
                    "inputs": [
                        {
                            "name": "pool",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RitualAuthorityTransferred",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "previousAuthority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newAuthority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleAdminChanged",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "previousAdminRole",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "newAdminRole",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleGranted",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleRevoked",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "StartAggregationRound",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "StartRitual",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "participants",
                            "type": "address[]",
                            "internalType": "address[]",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "TimeoutChanged",
                    "inputs": [
                        {
                            "name": "oldTimeout",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        },
                        {
                            "name": "newTimeout",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "TranscriptPosted",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "node",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "transcriptDigest",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "DEFAULT_ADMIN_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "INITIATOR_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "TREASURY_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "acceptDefaultAdminTransfer",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "application",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoChildApplication"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "beginDefaultAdminTransfer",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newAdmin",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "cancelDefaultAdminTransfer",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "changeDefaultAdminDelay",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newDelay",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "cohortFingerprint",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "nodes",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "currency",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IERC20"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "defaultAdmin",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "defaultAdminDelay",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "defaultAdminDelayIncreaseWait",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "feeDeduction",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "feeRatePerSecond",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getAuthority",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipant",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "transcript",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipantFromProvider",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipants",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple[]",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipants",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "maxParticipants",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "includeTranscript",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple[]",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getProviderPublicKey",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "ritualId",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word2",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                }
                            ],
                            "internalType": "struct BLS12381.G2Point"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getPublicKeyFromRitualId",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRitualIdFromPublicKey",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "dkgPublicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRitualInitiationCost",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRitualState",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint8",
                            "internalType": "enum Coordinator.RitualState"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRoleAdmin",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getThresholdForRitualSize",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "size",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "grantRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "hasRole",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_timeout",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "_maxDkgSize",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "_admin",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "initiateRitual",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]"
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isEncryptionAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "evidence",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "ciphertextHeader",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isInitiationPublic",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isParticipant",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isProviderPublicKeySet",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isRitualActive",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "makeInitiationPublic",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "maxDkgSize",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "numberOfRituals",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingDefaultAdmin",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "newAdmin",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "schedule",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingDefaultAdminDelay",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "newDelay",
                            "type": "uint48",
                            "internalType": "uint48"
                        },
                        {
                            "name": "schedule",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingFees",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "postAggregation",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "aggregatedTranscript",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "dkgPublicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        },
                        {
                            "name": "decryptionRequestStaticKey",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "postTranscript",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "transcript",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "processPendingFee",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "refundableFee",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "revokeRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rituals",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "initiator",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "initTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "endTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "totalTranscripts",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "totalAggregations",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "dkgSize",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "threshold",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "aggregationMismatch",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        },
                        {
                            "name": "publicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        },
                        {
                            "name": "aggregatedTranscript",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rollbackDefaultAdminDelay",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setMaxDkgSize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newSize",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setProviderPublicKey",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "publicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word2",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                }
                            ],
                            "internalType": "struct BLS12381.G2Point"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setReimbursementPool",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "pool",
                            "type": "address",
                            "internalType": "contract IReimbursementPool"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setTimeout",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newTimeout",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "supportsInterface",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "interfaceId",
                            "type": "bytes4",
                            "internalType": "bytes4"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "timeout",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "totalPendingFees",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferRitualAuthority",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "newAuthority",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "withdrawTokens",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "contract IERC20"
                        },
                        {
                            "name": "amount",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x4207f1fd038945ef7d5da06a0989446d3ed2eb031adad1233c7e3a61951652a1",
            "block_number": 5198668,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "GlobalAllowList": {
            "address": "0xfDBA7100B015586270B62bA116920b78F4ff6930",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_coordinator",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ECDSAInvalidSignature",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "ECDSAInvalidSignatureLength",
                    "inputs": [
                        {
                            "name": "length",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ECDSAInvalidSignatureS",
                    "inputs": [
                        {
                            "name": "s",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AddressAuthorizationSet",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "_address",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "isAuthorized",
                            "type": "bool",
                            "internalType": "bool",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "authorize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "addresses",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "coordinator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "deauthorize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "addresses",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "isAddressAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "encryptor",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "evidence",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "ciphertextHeader",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                }
            ],
            "tx_hash": "0xe1f8d7d3afac631312665faab5e45a4a8bbbfa7180f22014b5ad2dc0497edf72",
            "block_number": 5198678,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "LynxRitualToken": {
            "address": "0x064Be2a9740e565729BC0d47bC616c5bb8Cc87B9",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_totalSupplyOfTokens",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InsufficientAllowance",
                    "inputs": [
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "allowance",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "needed",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InsufficientBalance",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "balance",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "needed",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidApprover",
                    "inputs": [
                        {
                            "name": "approver",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidReceiver",
                    "inputs": [
                        {
                            "name": "receiver",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidSender",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ERC20InvalidSpender",
                    "inputs": [
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "Approval",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Transfer",
                    "inputs": [
                        {
                            "name": "from",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "allowance",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "approve",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "spender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "balanceOf",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "decimals",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint8",
                            "internalType": "uint8"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "name",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "string",
                            "internalType": "string"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "symbol",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "string",
                            "internalType": "string"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "totalSupply",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transfer",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferFrom",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "from",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "to",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                }
            ],
            "tx_hash": "0x3fa59f2ab18ce9017f5760b31bd7b132b387f1dd6e8c5d3fb8e58a553411ed69",
            "block_number": 5198656,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "MockPolygonChild": {
            "address": "0x4FD23FAB4A09F85872bf240ABBd484cb4F9a5F79",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "OwnableInvalidOwner",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AuthorizationUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorConfirmed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OwnershipTransferred",
                    "inputs": [
                        {
                            "name": "previousOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "childApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoRootToChild"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "confirmOperatorAddress",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setChildApplication",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_childApplication",
                            "type": "address",
                            "internalType": "contract ITACoRootToChild"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "transferOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_amount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0xee32aa290b2ea1a03cc01412e9089897ec92cabd3561664c26bf2c1e5e9247d4",
            "block_number": 5198636,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "OpenAccessAuthorizer": {
            "address": "0x33270a0B88d0Ffb6B0b4FBA119ca6a7263DeF675",
            "abi": [
                {
                    "type": "function",
                    "name": "isAuthorized",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                }
            ],
            "tx_hash": "0x8bbd39406559c99b6dc35df89c604c645b0996aac2908ce9e75f2d38367d294a",
            "block_number": 5196868,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "SubscriptionManager": {
            "address": "0x811389558a2C0B65ff56652d5E5bBF5DbC9A4358",
            "abi": [
                {
                    "type": "error",
                    "name": "AccessControlBadConfirmation",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "AccessControlUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "neededRole",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "event",
                    "name": "FeeRateUpdated",
                    "inputs": [
                        {
                            "name": "oldFeeRate",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        },
                        {
                            "name": "newFeeRate",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "PolicyCreated",
                    "inputs": [
                        {
                            "name": "policyId",
                            "type": "bytes16",
                            "internalType": "bytes16",
                            "indexed": true
                        },
                        {
                            "name": "sponsor",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "size",
                            "type": "uint16",
                            "internalType": "uint16",
                            "indexed": false
                        },
                        {
                            "name": "startTimestamp",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        },
                        {
                            "name": "endTimestamp",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        },
                        {
                            "name": "cost",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleAdminChanged",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "previousAdminRole",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "newAdminRole",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleGranted",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleRevoked",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "DEFAULT_ADMIN_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "SET_RATE_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "WITHDRAW_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "createPolicy",
                    "stateMutability": "payable",
                    "inputs": [
                        {
                            "name": "_policyId",
                            "type": "bytes16",
                            "internalType": "bytes16"
                        },
                        {
                            "name": "_policyOwner",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_size",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "_startTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "_endTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "feeRate",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getPolicy",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_policyID",
                            "type": "bytes16",
                            "internalType": "bytes16"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "sponsor",
                                    "type": "address",
                                    "internalType": "address payable"
                                },
                                {
                                    "name": "startTimestamp",
                                    "type": "uint32",
                                    "internalType": "uint32"
                                },
                                {
                                    "name": "endTimestamp",
                                    "type": "uint32",
                                    "internalType": "uint32"
                                },
                                {
                                    "name": "size",
                                    "type": "uint16",
                                    "internalType": "uint16"
                                },
                                {
                                    "name": "owner",
                                    "type": "address",
                                    "internalType": "address"
                                }
                            ],
                            "internalType": "struct SubscriptionManager.Policy"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getPolicyCost",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_size",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "_startTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "_endTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRoleAdmin",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "grantRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "hasRole",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_feeRate",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "isPolicyActive",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_policyID",
                            "type": "bytes16",
                            "internalType": "bytes16"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "callerConfirmation",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "revokeRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setFeeRate",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_ratePerSecond",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "supportsInterface",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "interfaceId",
                            "type": "bytes4",
                            "internalType": "bytes4"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "sweep",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "recipient",
                            "type": "address",
                            "internalType": "address payable"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x9a02db05c96318d8b0abfd5030c98cd013d670f46012ac1fbabf1564d40d2b6c",
            "block_number": 5347313,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        },
        "TACoChildApplication": {
            "address": "0x42F30AEc1A36995eEFaf9536Eb62BD751F982D32",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_rootApplication",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        },
                        {
                            "name": "_minimumAuthorization",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "event",
                    "name": "AuthorizationUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorConfirmed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "authorizedStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "confirmOperatorAddress",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "coordinator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getActiveStakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_maxStakingProviders",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "allAuthorizedTokens",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "activeStakingProviders",
                            "type": "bytes32[]",
                            "internalType": "bytes32[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getStakingProvidersLength",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_coordinator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "minimumAuthorization",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "operatorToStakingProvider",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rootApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviderInfo",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "operatorConfirmed",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "index",
                            "type": "uint248",
                            "internalType": "uint248"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "updateAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x4a1de1566b64df4f11a782939b4b694cb193df9bb06b307835a87e2420f37e81",
            "block_number": 5198646,
            "deployer": "0x3B42d26E19FF860bC4dEbB920DD8caA53F93c600"
        }
    }
}
;