export default {
    "1": {
        "PolygonRoot": {
            "address": "0x51825d6e893c51836dC9C0EdF3867c57CD0cACB3",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_checkpointManager",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fxRoot",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_rootApplication",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fxChildTunnel",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "fallback",
                    "stateMutability": "nonpayable"
                },
                {
                    "type": "function",
                    "name": "SEND_MESSAGE_EVENT_SIG",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "checkpointManager",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ICheckpointManager"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "fxChildTunnel",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "fxRoot",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IFxStateSender"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "processedExits",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "receiveMessage",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "inputData",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rootApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "setFxChildTunnel",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_fxChildTunnel",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x6f312fa728d914beabdb6164ccc5863c869874957318f8b6d618ac5448bec55a",
            "block_number": 18728539,
            "deployer": "0xFfFd7092685bDeeBD121D1A0FEA3c349114Cce50"
        },
        "TACoApplication": {
            "address": "0x347CC7ede7e5517bD47D20620B2CF1b406edcF07",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_token",
                            "type": "address",
                            "internalType": "contract IERC20"
                        },
                        {
                            "name": "_tStaking",
                            "type": "address",
                            "internalType": "contract IStaking"
                        },
                        {
                            "name": "_minimumAuthorization",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_minOperatorSeconds",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_rewardDuration",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_deauthorizationDuration",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_commitmentDurationOptions",
                            "type": "uint64[]",
                            "internalType": "uint64[]"
                        },
                        {
                            "name": "_commitmentDeadline",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressEmptyCode",
                    "inputs": [
                        {
                            "name": "target",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressInsufficientBalance",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "FailedInnerCall",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "OwnableInvalidOwner",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "SafeCastOverflowedUintDowncast",
                    "inputs": [
                        {
                            "name": "bits",
                            "type": "uint8",
                            "internalType": "uint8"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "SafeERC20FailedOperation",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AuthorizationDecreaseApproved",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationDecreaseRequested",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationIncreased",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationInvoluntaryDecreased",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "AuthorizationReSynchronized",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "fromAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "toAmount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "CommitmentMade",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "endCommitment",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "ManualChildSynchronizationSent",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "deauthorizing",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "endDeauthorization",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorBonded",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "previousOperator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "startTimestamp",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorConfirmed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OwnershipTransferred",
                    "inputs": [
                        {
                            "name": "previousOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardAdded",
                    "inputs": [
                        {
                            "name": "reward",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardDistributorSet",
                    "inputs": [
                        {
                            "name": "distributor",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardPaid",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "beneficiary",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "reward",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RewardsWithdrawn",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "amount",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Slashed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "penalty",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        },
                        {
                            "name": "investigator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "reward",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "REWARD_PER_TOKEN_MULTIPLIER",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "adjudicator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "approveAuthorizationDecrease",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizationDecreaseRequested",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizationIncreased",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "authorizationParameters",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "_minimumAuthorization",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "authorizationDecreaseDelay",
                            "type": "uint64",
                            "internalType": "uint64"
                        },
                        {
                            "name": "authorizationDecreaseChangePeriod",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "authorizedOverall",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "authorizedStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "availableRewards",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "bondOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "childApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoRootToChild"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDeadline",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption1",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption2",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption3",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "commitmentDurationOption4",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "confirmOperatorAddress",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "deauthorizationDuration",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "eligibleStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_endDate",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getActiveStakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_maxStakingProviders",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_cohortDuration",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "allAuthorizedTokens",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "activeStakingProviders",
                            "type": "bytes32[]",
                            "internalType": "bytes32[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getBeneficiary",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "beneficiary",
                            "type": "address",
                            "internalType": "address payable"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getStakingProvidersLength",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "involuntaryAuthorizationDecrease",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_fromAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_toAmount",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "isAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isOperatorConfirmed",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "lastTimeRewardApplicable",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "lastUpdateTime",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "makeCommitment",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_commitmentDuration",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "manualChildSynchronization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "minOperatorSeconds",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "minimumAuthorization",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "operatorToStakingProvider",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingAuthorizationDecrease",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "periodFinish",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pushReward",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_reward",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "registerOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "remainingAuthorizationDecreaseDelay",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "resynchronizeAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rewardDistributor",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardDuration",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardPerToken",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint160",
                            "internalType": "uint160"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardPerTokenStored",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint160",
                            "internalType": "uint160"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rewardRateDecimals",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "setAdjudicator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_adjudicator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setChildApplication",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_childApplication",
                            "type": "address",
                            "internalType": "contract ITACoRootToChild"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setRewardDistributor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_rewardDistributor",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "slash",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_penalty",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "_investigator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "stakingProviderInfo",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "operatorConfirmed",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "operatorStartTimestamp",
                            "type": "uint64",
                            "internalType": "uint64"
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "deauthorizing",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "endDeauthorization",
                            "type": "uint64",
                            "internalType": "uint64"
                        },
                        {
                            "name": "tReward",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "rewardPerTokenPaid",
                            "type": "uint160",
                            "internalType": "uint160"
                        },
                        {
                            "name": "endCommitment",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviderToOperator",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "tStaking",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IStaking"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "token",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IERC20"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "withdrawRewards",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x58617564fa2bf9ad71b9eab1ceefdd3eae5a46efc71f4463df6dbffbd713a160",
            "block_number": 18622368,
            "deployer": "0xFfFd7092685bDeeBD121D1A0FEA3c349114Cce50"
        }
    },
    "137": {
        "BetaProgramInitiator": {
            "address": "0x7CEbC88351061b2721865f01d2aCEc4c3eC92E8d",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_coordinator",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        },
                        {
                            "name": "_executor",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressEmptyCode",
                    "inputs": [
                        {
                            "name": "target",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressInsufficientBalance",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "FailedInnerCall",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "SafeERC20FailedOperation",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "FailedRequestRefunded",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        },
                        {
                            "name": "refundAmount",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RequestCanceled",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RequestExecuted",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        },
                        {
                            "name": "ritualId",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RequestRegistered",
                    "inputs": [
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": true
                        },
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]",
                            "indexed": false
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": false
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer",
                            "indexed": false
                        },
                        {
                            "name": "payment",
                            "type": "uint256",
                            "internalType": "uint256",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "NO_RITUAL",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "cancelInitiationRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "coordinator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "currency",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IERC20"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "executeInitiationRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "executor",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRequestsLength",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "refundFailedRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "registerInitiationRequest",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]"
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "requestIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "requests",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "payment",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                }
            ],
            "tx_hash": "0xca501b1153acf6d47a2def2413fc5c72302f6d87c050f4e859eacc888e3fc492",
            "block_number": 52701776,
            "deployer": "0x1591165F1BF8B73de7053A6BE6f239BC15076879"
        },
        "Coordinator": {
            "address": "0xE74259e3dafe30bAA8700238e324b47aC98FE755",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_application",
                            "type": "address",
                            "internalType": "contract ITACoChildApplication"
                        },
                        {
                            "name": "_currency",
                            "type": "address",
                            "internalType": "contract IERC20"
                        },
                        {
                            "name": "_feeRatePerSecond",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AccessControlBadConfirmation",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "AccessControlEnforcedDefaultAdminDelay",
                    "inputs": [
                        {
                            "name": "schedule",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AccessControlEnforcedDefaultAdminRules",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "AccessControlInvalidDefaultAdmin",
                    "inputs": [
                        {
                            "name": "defaultAdmin",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AccessControlUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "neededRole",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressEmptyCode",
                    "inputs": [
                        {
                            "name": "target",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "AddressInsufficientBalance",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "FailedInnerCall",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "SafeCastOverflowedUintDowncast",
                    "inputs": [
                        {
                            "name": "bits",
                            "type": "uint8",
                            "internalType": "uint8"
                        },
                        {
                            "name": "value",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "SafeERC20FailedOperation",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AggregationPosted",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "node",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "aggregatedTranscriptDigest",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminDelayChangeCanceled",
                    "inputs": [],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminDelayChangeScheduled",
                    "inputs": [
                        {
                            "name": "newDelay",
                            "type": "uint48",
                            "internalType": "uint48",
                            "indexed": false
                        },
                        {
                            "name": "effectSchedule",
                            "type": "uint48",
                            "internalType": "uint48",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminTransferCanceled",
                    "inputs": [],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "DefaultAdminTransferScheduled",
                    "inputs": [
                        {
                            "name": "newAdmin",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "acceptSchedule",
                            "type": "uint48",
                            "internalType": "uint48",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "EndRitual",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "successful",
                            "type": "bool",
                            "internalType": "bool",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "MaxDkgSizeChanged",
                    "inputs": [
                        {
                            "name": "oldSize",
                            "type": "uint16",
                            "internalType": "uint16",
                            "indexed": false
                        },
                        {
                            "name": "newSize",
                            "type": "uint16",
                            "internalType": "uint16",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "ParticipantPublicKeySet",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "participant",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "publicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word2",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                }
                            ],
                            "internalType": "struct BLS12381.G2Point",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "ReimbursementPoolSet",
                    "inputs": [
                        {
                            "name": "pool",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RitualAuthorityTransferred",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "previousAuthority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newAuthority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleAdminChanged",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "previousAdminRole",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "newAdminRole",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleGranted",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "RoleRevoked",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": true
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "sender",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "StartAggregationRound",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "StartRitual",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "participants",
                            "type": "address[]",
                            "internalType": "address[]",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "TimeoutChanged",
                    "inputs": [
                        {
                            "name": "oldTimeout",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        },
                        {
                            "name": "newTimeout",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "TranscriptPosted",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "node",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "transcriptDigest",
                            "type": "bytes32",
                            "internalType": "bytes32",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "DEFAULT_ADMIN_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "INITIATOR_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "TREASURY_ROLE",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "acceptDefaultAdminTransfer",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "application",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoChildApplication"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "beginDefaultAdminTransfer",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newAdmin",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "cancelDefaultAdminTransfer",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "changeDefaultAdminDelay",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newDelay",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "cohortFingerprint",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "nodes",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "currency",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract IERC20"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "defaultAdmin",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "defaultAdminDelay",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "defaultAdminDelayIncreaseWait",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "feeDeduction",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "feeRatePerSecond",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getAuthority",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipant",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "transcript",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipantFromProvider",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipants",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple[]",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getParticipants",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "maxParticipants",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "includeTranscript",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple[]",
                            "components": [
                                {
                                    "name": "provider",
                                    "type": "address",
                                    "internalType": "address"
                                },
                                {
                                    "name": "aggregated",
                                    "type": "bool",
                                    "internalType": "bool"
                                },
                                {
                                    "name": "transcript",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                },
                                {
                                    "name": "decryptionRequestStaticKey",
                                    "type": "bytes",
                                    "internalType": "bytes"
                                }
                            ],
                            "internalType": "struct Coordinator.Participant[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getProviderPublicKey",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "ritualId",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word2",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                }
                            ],
                            "internalType": "struct BLS12381.G2Point"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getPublicKeyFromRitualId",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRitualIdFromPublicKey",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "dkgPublicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRitualInitiationCost",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRitualState",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint8",
                            "internalType": "enum Coordinator.RitualState"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getRoleAdmin",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getThresholdForRitualSize",
                    "stateMutability": "pure",
                    "inputs": [
                        {
                            "name": "size",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "grantRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "hasRole",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_timeout",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "_maxDkgSize",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "_admin",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "initiateRitual",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "providers",
                            "type": "address[]",
                            "internalType": "address[]"
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "duration",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isEncryptionAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "evidence",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "ciphertextHeader",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isInitiationPublic",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isParticipant",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isProviderPublicKeySet",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "provider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isRitualActive",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "makeInitiationPublic",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "maxDkgSize",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "numberOfRituals",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingDefaultAdmin",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "newAdmin",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "schedule",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingDefaultAdminDelay",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "newDelay",
                            "type": "uint48",
                            "internalType": "uint48"
                        },
                        {
                            "name": "schedule",
                            "type": "uint48",
                            "internalType": "uint48"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingFees",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "postAggregation",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "aggregatedTranscript",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "dkgPublicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        },
                        {
                            "name": "decryptionRequestStaticKey",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "postTranscript",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "transcript",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "processPendingFee",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "refundableFee",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "renounceRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "revokeRole",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "role",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        },
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "rituals",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "initiator",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "initTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "endTimestamp",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "totalTranscripts",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "totalAggregations",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "authority",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "dkgSize",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "threshold",
                            "type": "uint16",
                            "internalType": "uint16"
                        },
                        {
                            "name": "aggregationMismatch",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "accessController",
                            "type": "address",
                            "internalType": "contract IEncryptionAuthorizer"
                        },
                        {
                            "name": "publicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes16",
                                    "internalType": "bytes16"
                                }
                            ],
                            "internalType": "struct BLS12381.G1Point"
                        },
                        {
                            "name": "aggregatedTranscript",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rollbackDefaultAdminDelay",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setMaxDkgSize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newSize",
                            "type": "uint16",
                            "internalType": "uint16"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setProviderPublicKey",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "publicKey",
                            "type": "tuple",
                            "components": [
                                {
                                    "name": "word0",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word1",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                },
                                {
                                    "name": "word2",
                                    "type": "bytes32",
                                    "internalType": "bytes32"
                                }
                            ],
                            "internalType": "struct BLS12381.G2Point"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setReimbursementPool",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "pool",
                            "type": "address",
                            "internalType": "contract IReimbursementPool"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setTimeout",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newTimeout",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "supportsInterface",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "interfaceId",
                            "type": "bytes4",
                            "internalType": "bytes4"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "timeout",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "totalPendingFees",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "transferRitualAuthority",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "newAuthority",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "withdrawTokens",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "token",
                            "type": "address",
                            "internalType": "contract IERC20"
                        },
                        {
                            "name": "amount",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x2a7f64225d9884b6b854ba7454283ecd4730785cbc862b72d4d8111224688e13",
            "block_number": 50224074,
            "deployer": "0xFfFd7092685bDeeBD121D1A0FEA3c349114Cce50"
        },
        "GlobalAllowList": {
            "address": "0xa8D488019F6627C4eA806242CbEc06EaF7CfA03c",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_coordinator",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ECDSAInvalidSignature",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "ECDSAInvalidSignatureLength",
                    "inputs": [
                        {
                            "name": "length",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "ECDSAInvalidSignatureS",
                    "inputs": [
                        {
                            "name": "s",
                            "type": "bytes32",
                            "internalType": "bytes32"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "AddressAuthorizationSet",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32",
                            "indexed": true
                        },
                        {
                            "name": "_address",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "isAuthorized",
                            "type": "bool",
                            "internalType": "bool",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "authorize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "addresses",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "coordinator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract Coordinator"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "deauthorize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "addresses",
                            "type": "address[]",
                            "internalType": "address[]"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "isAddressAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "encryptor",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "isAuthorized",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "ritualId",
                            "type": "uint32",
                            "internalType": "uint32"
                        },
                        {
                            "name": "evidence",
                            "type": "bytes",
                            "internalType": "bytes"
                        },
                        {
                            "name": "ciphertextHeader",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "bool",
                            "internalType": "bool"
                        }
                    ]
                }
            ],
            "tx_hash": "0x8b394cc41255d7b580d1561c5236b020ec2048782a1e7553f6f476f06ea61c13",
            "block_number": 50224144,
            "deployer": "0xFfFd7092685bDeeBD121D1A0FEA3c349114Cce50"
        },
        "PolygonChild": {
            "address": "0x1f5C5fd6A66723fA22a778CC53263dd3FA6851E5",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_fxChild",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableInvalidOwner",
                    "inputs": [
                        {
                            "name": "owner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "OwnableUnauthorizedAccount",
                    "inputs": [
                        {
                            "name": "account",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "event",
                    "name": "MessageSent",
                    "inputs": [
                        {
                            "name": "message",
                            "type": "bytes",
                            "internalType": "bytes",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OwnershipTransferred",
                    "inputs": [
                        {
                            "name": "previousOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "fallback",
                    "stateMutability": "nonpayable"
                },
                {
                    "type": "function",
                    "name": "childApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "fxChild",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "fxRootTunnel",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "owner",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "processMessageFromRoot",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stateId",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "rootMessageSender",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "data",
                            "type": "bytes",
                            "internalType": "bytes"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "renounceOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setChildApplication",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_childApplication",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "setFxRootTunnel",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_fxRootTunnel",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "transferOwnership",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "newOwner",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0xad8835aa771ea9d0038a54f8065d6b0b2d3d1e592b817aa2932ce763e35f9f60",
            "block_number": 50816123,
            "deployer": "0x1591165F1BF8B73de7053A6BE6f239BC15076879"
        },
        "SubscriptionManager": {
            "address": "0xB0194073421192F6Cf38d72c791Be8729721A0b3",
            "abi": [
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "oldFeeRate",
                            "type": "uint256"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "newFeeRate",
                            "type": "uint256"
                        }
                    ],
                    "name": "FeeRateUpdated",
                    "type": "event"
                },
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": true,
                            "internalType": "bytes16",
                            "name": "policyId",
                            "type": "bytes16"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "sponsor",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "owner",
                            "type": "address"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint16",
                            "name": "size",
                            "type": "uint16"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint32",
                            "name": "startTimestamp",
                            "type": "uint32"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint32",
                            "name": "endTimestamp",
                            "type": "uint32"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "cost",
                            "type": "uint256"
                        }
                    ],
                    "name": "PolicyCreated",
                    "type": "event"
                },
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": true,
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "indexed": true,
                            "internalType": "bytes32",
                            "name": "previousAdminRole",
                            "type": "bytes32"
                        },
                        {
                            "indexed": true,
                            "internalType": "bytes32",
                            "name": "newAdminRole",
                            "type": "bytes32"
                        }
                    ],
                    "name": "RoleAdminChanged",
                    "type": "event"
                },
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": true,
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "sender",
                            "type": "address"
                        }
                    ],
                    "name": "RoleGranted",
                    "type": "event"
                },
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": true,
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "sender",
                            "type": "address"
                        }
                    ],
                    "name": "RoleRevoked",
                    "type": "event"
                },
                {
                    "inputs": [],
                    "name": "DEFAULT_ADMIN_ROLE",
                    "outputs": [
                        {
                            "internalType": "bytes32",
                            "name": "",
                            "type": "bytes32"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "SET_RATE_ROLE",
                    "outputs": [
                        {
                            "internalType": "bytes32",
                            "name": "",
                            "type": "bytes32"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "WITHDRAW_ROLE",
                    "outputs": [
                        {
                            "internalType": "bytes32",
                            "name": "",
                            "type": "bytes32"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes16",
                            "name": "_policyId",
                            "type": "bytes16"
                        },
                        {
                            "internalType": "address",
                            "name": "_policyOwner",
                            "type": "address"
                        },
                        {
                            "internalType": "uint16",
                            "name": "_size",
                            "type": "uint16"
                        },
                        {
                            "internalType": "uint32",
                            "name": "_startTimestamp",
                            "type": "uint32"
                        },
                        {
                            "internalType": "uint32",
                            "name": "_endTimestamp",
                            "type": "uint32"
                        }
                    ],
                    "name": "createPolicy",
                    "outputs": [],
                    "stateMutability": "payable",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "feeRate",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes16",
                            "name": "_policyID",
                            "type": "bytes16"
                        }
                    ],
                    "name": "getPolicy",
                    "outputs": [
                        {
                            "components": [
                                {
                                    "internalType": "address payable",
                                    "name": "sponsor",
                                    "type": "address"
                                },
                                {
                                    "internalType": "uint32",
                                    "name": "startTimestamp",
                                    "type": "uint32"
                                },
                                {
                                    "internalType": "uint32",
                                    "name": "endTimestamp",
                                    "type": "uint32"
                                },
                                {
                                    "internalType": "uint16",
                                    "name": "size",
                                    "type": "uint16"
                                },
                                {
                                    "internalType": "address",
                                    "name": "owner",
                                    "type": "address"
                                }
                            ],
                            "internalType": "struct SubscriptionManager.Policy",
                            "name": "",
                            "type": "tuple"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint16",
                            "name": "_size",
                            "type": "uint16"
                        },
                        {
                            "internalType": "uint32",
                            "name": "_startTimestamp",
                            "type": "uint32"
                        },
                        {
                            "internalType": "uint32",
                            "name": "_endTimestamp",
                            "type": "uint32"
                        }
                    ],
                    "name": "getPolicyCost",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        }
                    ],
                    "name": "getRoleAdmin",
                    "outputs": [
                        {
                            "internalType": "bytes32",
                            "name": "",
                            "type": "bytes32"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "name": "grantRole",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "name": "hasRole",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "_feeRate",
                            "type": "uint256"
                        }
                    ],
                    "name": "initialize",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes16",
                            "name": "_policyID",
                            "type": "bytes16"
                        }
                    ],
                    "name": "isPolicyActive",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "name": "renounceRole",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes32",
                            "name": "role",
                            "type": "bytes32"
                        },
                        {
                            "internalType": "address",
                            "name": "account",
                            "type": "address"
                        }
                    ],
                    "name": "revokeRole",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "uint256",
                            "name": "_ratePerSecond",
                            "type": "uint256"
                        }
                    ],
                    "name": "setFeeRate",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "bytes4",
                            "name": "interfaceId",
                            "type": "bytes4"
                        }
                    ],
                    "name": "supportsInterface",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "view",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address payable",
                            "name": "recipient",
                            "type": "address"
                        }
                    ],
                    "name": "sweep",
                    "outputs": [],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }
            ],
            "tx_hash": "0xcb0dcb4414557bd56e158156ab32e84aa77af3f58b1799bedb119d46e950d3d0",
            "block_number": 25163557,
            "deployer": "0xf4cef231159750e1199c4577716f0e71604f1216"
        },
        "TACoChildApplication": {
            "address": "0xFa07aaB78062Fac4C36995bF28F6D677667973F5",
            "abi": [
                {
                    "type": "constructor",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_rootApplication",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        },
                        {
                            "name": "_minimumAuthorization",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "error",
                    "name": "InvalidInitialization",
                    "inputs": []
                },
                {
                    "type": "error",
                    "name": "NotInitializing",
                    "inputs": []
                },
                {
                    "type": "event",
                    "name": "AuthorizationUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "deauthorizing",
                            "type": "uint96",
                            "internalType": "uint96",
                            "indexed": false
                        },
                        {
                            "name": "endDeauthorization",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "Initialized",
                    "inputs": [
                        {
                            "name": "version",
                            "type": "uint64",
                            "internalType": "uint64",
                            "indexed": false
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorConfirmed",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "event",
                    "name": "OperatorUpdated",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address",
                            "indexed": true
                        }
                    ],
                    "anonymous": false
                },
                {
                    "type": "function",
                    "name": "authorizedStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "confirmOperatorAddress",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "coordinator",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "eligibleStake",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "_endDate",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getActiveStakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_maxStakingProviders",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_cohortDuration",
                            "type": "uint32",
                            "internalType": "uint32"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "allAuthorizedTokens",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "activeStakingProviders",
                            "type": "bytes32[]",
                            "internalType": "bytes32[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getActiveStakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_startIndex",
                            "type": "uint256",
                            "internalType": "uint256"
                        },
                        {
                            "name": "_maxStakingProviders",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "allAuthorizedTokens",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "activeStakingProviders",
                            "type": "bytes32[]",
                            "internalType": "bytes32[]"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "getStakingProvidersLength",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "initialize",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "_coordinator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "minimumAuthorization",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "operatorToStakingProvider",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "pendingAuthorizationDecrease",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "_stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "rootApplication",
                    "stateMutability": "view",
                    "inputs": [],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "contract ITACoChildToRoot"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviderInfo",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "operatorConfirmed",
                            "type": "bool",
                            "internalType": "bool"
                        },
                        {
                            "name": "index",
                            "type": "uint248",
                            "internalType": "uint248"
                        },
                        {
                            "name": "deauthorizing",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "endDeauthorization",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "stakingProviders",
                    "stateMutability": "view",
                    "inputs": [
                        {
                            "name": "",
                            "type": "uint256",
                            "internalType": "uint256"
                        }
                    ],
                    "outputs": [
                        {
                            "name": "",
                            "type": "address",
                            "internalType": "address"
                        }
                    ]
                },
                {
                    "type": "function",
                    "name": "updateAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateAuthorization",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "authorized",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "deauthorizing",
                            "type": "uint96",
                            "internalType": "uint96"
                        },
                        {
                            "name": "endDeauthorization",
                            "type": "uint64",
                            "internalType": "uint64"
                        }
                    ],
                    "outputs": []
                },
                {
                    "type": "function",
                    "name": "updateOperator",
                    "stateMutability": "nonpayable",
                    "inputs": [
                        {
                            "name": "stakingProvider",
                            "type": "address",
                            "internalType": "address"
                        },
                        {
                            "name": "operator",
                            "type": "address",
                            "internalType": "address"
                        }
                    ],
                    "outputs": []
                }
            ],
            "tx_hash": "0x6eeafdcd35f18a83e9ba17ba67483b56ae21a5611b53f9b11da026ac073b5558",
            "block_number": 50223997,
            "deployer": "0xFfFd7092685bDeeBD121D1A0FEA3c349114Cce50"
        }
    }
}
;